export const colors = {
    mattBlack: '#171717',
    lightMattBlack: '#28282B',
    primaryWhite: '	#FFFFF0',
    mainBlack: '#000000',
    secondBlack: '#020202',
    mainGreen: '#1bde8c',
    secondGreen: '#59d3c8',
    darkJungleGreen: '#1A2421',
    secondMattBlack: '#2F2F31',
    secondDark: '#1c1c1c',
    // new color sets
    mainBlue: '#17fffe',
    secondBlue: '#00fcfb',
    // language colors
    htmlColor: '#E34C26',
    jsColor: '#f0db4f',
    cssColor: '#2965f1',
    reactColor: '#88dded',
    materialColor: '#2196f3',
    sassColor: '#cc6699',
    chartColor: '#FF5349',
    tsColor: '#007acc',
    framerColor: '#A228FF',
    threeJs: '#ffff31',
}